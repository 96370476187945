<template>
	<div>
		<el-container>
			<el-main>
				<div class="public-margin-bottom flex-right">
					<el-button 
						type="primary" 
						icon="el-icon-plus" 
						size="mini"
						@click="onAddDepartment">
							新增部门
					</el-button>
				</div>
				<el-table
				:data="tableData"
				style="width: 100%"
				border
				height="calc(100vh - 148px)"
				stripe>
					<el-table-column
						prop="DeptNo"
						label="部门编号">
					</el-table-column>
					<el-table-column
						prop="DeptName"
						label="部门名称">
					</el-table-column>
					<el-table-column label="日结短信">
						<template slot-scope="scope">
							<div>{{ scope.row.DayEndSms | whether }}</div>
						</template>
					</el-table-column>
					<el-table-column label="是属总部">
						<template slot-scope="scope">
							<div>{{ scope.row.HeadMark | whether }}</div>
						</template>
					</el-table-column>
					<el-table-column label="操作">
						<template slot-scope="scope">
							<el-button size="mini" type="primary" @click="onEditDepartment(scope.row)">编辑</el-button>
							<el-button size="mini" type="danger" @click="onDelectDepartment(scope.row)">删除</el-button>
						</template>
					</el-table-column>
				</el-table>
			</el-main>
			<!-- end el-main -->
		</el-container>
		<EditDialog 
			:formData="formData" 
			:etitle="etitle" 
			:dialogTableVisible="dialogTableVisible" 
			:callFatherFunction="callFatherFunction"
			@closeDialog="onCloseDialog">
		</EditDialog>
	</div>
</template>

<script>
	import employee from '../../../api/employee.js';
	import EditDialog from './components/edit-dialog.vue';
	export default {
		components: { EditDialog },
		data() {
			return {
				indexAction: 0,
				tableData:[],
				dialogTableVisible: false,
				etitle: '',
				formData: {}
			}
		},
		mounted() {
			this.initPageData();
		},
		
		filters: {
			whether(param){
				return param == 0 ? '否' : '是';
			}
		},
		
		methods:{
			// 获取数据
			async initPageData(){
				try{
					let { data, errcode, errmsg } = await employee.getDepartment({});
					if(errcode == 0){
						this.tableData = data;
					}else{
						this.$message.error(errmsg);
					}
				}catch(e){
					
				}
			},
			
			// 删除数据
			onDelectDepartment(event){
				this.$confirm(`是否删除该‘${event.DeptName}’`, '提示', {
					confirmButtonText: '确定',
					cancelButtonText: '取消',
					type: 'warning'
				}).then(async () => {
					try{
						let { data, errcode, errmsg } = await employee.deleteDepartment({ dept_guid: event.DeptGuid });
						if(errcode == 0){
							this.$message({ 
								message: '删除成功!',
							  type: 'success'
							});
							this.initPageData();
						}else{
							this.$message.error(errmsg);
						}
					}catch(e){
						console.log(e)
					}
				}).catch(() => {
					this.$message({
						type: 'info',
						message: '已取消删除'
					});          
				});
			},
			
			onEditDepartment(event){
				this.etitle = '编辑部门';
				this.formData = event;
				this.dialogTableVisible = true;
			},
			
			// 添加数据
			onAddDepartment(event){
				this.etitle = '添加部门';
				this.formData = {
					DeptName: '',
					DayEndSms: ''
				};
				this.dialogTableVisible = true;
			},
			
			// 
			callFatherFunction(){
				this.dialogTableVisible = false;
			},
			
			onCloseDialog(){
				this.initPageData();
			}
		}
	}
</script>

<style lang="less" scoped>
.el-container {
  .el-aside {
    height: calc(100vh - 100px);
    border-right: 1px solid #d3dce6;
    .jobs{
    	height: 40px;
    	line-height: 40px;
			padding-left: 15px;
			color: #606266;
			margin-bottom: 10px;
    }
		.position{
			height: 36px;
			line-height: 36px;
			padding-left: 15px;
			cursor: pointer;
		}
		.action{
			background-color: #f5f7fa;
		}
  }
	.el-main {
	  position: relative;
		padding: 0;
	}
}
</style>
